import React, { useState, useMemo, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import TiersEditor from './TiersEditor';
import { saveAs } from 'file-saver';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { FaTrash, FaPlusSquare, FaMinusSquare } from 'react-icons/fa';
import CurrencyConverter from './CurrencyConverter';

const initialTiers = [
{ max: 100000, rate: 0.0300000000 },
{ max: 130000, rate: 0.0298333333 },
{ max: 160000, rate: 0.0296666667 },
{ max: 190000, rate: 0.0295000000 },
{ max: 220000, rate: 0.0293333333 },
{ max: 250000, rate: 0.0291666667 },
{ max: 280000, rate: 0.0290000000 },
{ max: 310000, rate: 0.0288333333 },
{ max: 340000, rate: 0.0286666667 },
{ max: 370000, rate: 0.0285000000 },
{ max: 400000, rate: 0.0283333333 },
{ max: 430000, rate: 0.0281666667 },
{ max: 460000, rate: 0.0280000000 },
{ max: 490000, rate: 0.0278333333 },
{ max: 520000, rate: 0.0276666667 },
{ max: 550000, rate: 0.0275000000 },
{ max: 580000, rate: 0.0273333333 },
{ max: 610000, rate: 0.0271666667 },
{ max: 640000, rate: 0.0270000000 },
{ max: 670000, rate: 0.0268333333 },
{ max: 700000, rate: 0.0266666667 },
{ max: 730000, rate: 0.0265000000 },
{ max: 760000, rate: 0.0263333333 },
{ max: 790000, rate: 0.0261666667 },
{ max: 820000, rate: 0.0260000000 },
{ max: 850000, rate: 0.0258333333 },
{ max: 880000, rate: 0.0256666667 },
{ max: 910000, rate: 0.0255000000 },
{ max: 940000, rate: 0.0253333333 },
{ max: 970000, rate: 0.0251666667 },
{ max: 1000000, rate: 0.0250000000 },
{ max: 1060000, rate: 0.0247058824 },
{ max: 1120000, rate: 0.0244117647 },
{ max: 1180000, rate: 0.0241176471 },
{ max: 1240000, rate: 0.0238235294 },
{ max: 1300000, rate: 0.0235294118 },
{ max: 1360000, rate: 0.0232352941 },
{ max: 1420000, rate: 0.0229411765 },
{ max: 1480000, rate: 0.0226470588 },
{ max: 1540000, rate: 0.0223529412 },
{ max: 1600000, rate: 0.0220588235 },
{ max: 1660000, rate: 0.0217647059 },
{ max: 1720000, rate: 0.0214705882 },
{ max: 1780000, rate: 0.0211764706 },
{ max: 1840000, rate: 0.0208823529 },
{ max: 1900000, rate: 0.0205882353 },
{ max: 1960000, rate: 0.0202941176 },
{ max: 2000000, rate: 0.0200000000 },
{ max: 2090000, rate: 0.0195833333 },
{ max: 2180000, rate: 0.0191666667 },
{ max: 2270000, rate: 0.0187500000 },
{ max: 2360000, rate: 0.0183333333 },
{ max: 2450000, rate: 0.0179166667 },
{ max: 2540000, rate: 0.0175000000 },
{ max: 2630000, rate: 0.0170833333 },
{ max: 2720000, rate: 0.0166666667 },
{ max: 2810000, rate: 0.0162500000 },
{ max: 2900000, rate: 0.0158333333 },
{ max: 2990000, rate: 0.0154166667 },
{ max: 3000000, rate: 0.0150000000 },
{ max: 3300000, rate: 0.0147916667 },
{ max: 3600000, rate: 0.0145833333 },
{ max: 3900000, rate: 0.0143750000 },
{ max: 4200000, rate: 0.0141666667 },
{ max: 4500000, rate: 0.0139583333 },
{ max: 4800000, rate: 0.0137500000 },
{ max: 5100000, rate: 0.0135416667 },
{ max: 5400000, rate: 0.0133333333 },
{ max: 5700000, rate: 0.0131250000 },
{ max: 6000000, rate: 0.0129166667 },
{ max: 6300000, rate: 0.0127083333 },
{ max: 6600000, rate: 0.0125000000 },
{ max: 6900000, rate: 0.0122916667 },
{ max: 7200000, rate: 0.0120833333 },
{ max: 7500000, rate: 0.011875 },
{ max: 7800000, rate: 0.0116666667 },
{ max: 8100000, rate: 0.0114583333 },
{ max: 8400000, rate: 0.01125 },
{ max: 8700000, rate: 0.0110416667 },
{ max: 9000000, rate: 0.0108333333 },
{ max: 9300000, rate: 0.010625 },
{ max: 9600000, rate: 0.0104166667 },
{ max: 9900000, rate: 0.0102083333 },
{ max: 10000000, rate: 0.01 },
{ max: 10450000, rate: 0.0097916667 },
{ max: 10900000, rate: 0.0095833333 },
{ max: 11350000, rate: 0.009375 },
{ max: 11800000, rate: 0.0091666667 },
{ max: 12250000, rate: 0.0089583333 },
{ max: 12700000, rate: 0.00875 },
{ max: 13150000, rate: 0.0085416667 },
{ max: 13600000, rate: 0.0083333333 },
{ max: 14050000, rate: 0.008125 },
{ max: 14500000, rate: 0.0079166667 },
{ max: 14950000, rate: 0.0077083333 },
{ max: 15000000, rate: 0.0075 },
{ max: 15750000, rate: 0.0073214286 },
{ max: 16500000, rate: 0.0071428571 },
{ max: 17250000, rate: 0.0069642857 },
{ max: 18000000, rate: 0.0067857143 },
{ max: 18750000, rate: 0.0066071429 },
{ max: 19500000, rate: 0.0064285714 },
{ max: 20250000, rate: 0.00625 },
{ max: 21000000, rate: 0.0060714286 },
{ max: 21750000, rate: 0.0058928571 },
{ max: 22500000, rate: 0.0057142857 },
{ max: 23250000, rate: 0.0055357143 },
{ max: 24000000, rate: 0.0053571429 },
{ max: 24750000, rate: 0.0051785714 },
{ max: 25000000, rate: 0.005 },
{ max: 26500000, rate: 0.0049117647 },
{ max: 28000000, rate: 0.0048235294 },
{ max: 29500000, rate: 0.0047352941 },
{ max: 31000000, rate: 0.0046470588 },
{ max: 32500000, rate: 0.0045588235 },
{ max: 34000000, rate: 0.0044705882 },
{ max: 35500000, rate: 0.0043823529 },
{ max: 37000000, rate: 0.0042941176 },
{ max: 38500000, rate: 0.0042058824 },
{ max: 40000000, rate: 0.0041176471 },
{ max: 41500000, rate: 0.0040294118 },
{ max: 43000000, rate: 0.0039411765 },
{ max: 44500000, rate: 0.0038529412 },
{ max: 46000000, rate: 0.0037647059 },
{ max: 47500000, rate: 0.0036764706 },
{ max: 49000000, rate: 0.0035882353 },
{ max: 50000000, rate: 0.0035 },
{ max: 53000000, rate: 0.0034411765 },
{ max: 56000000, rate: 0.0033823529 },
{ max: 59000000, rate: 0.0033235294 },
{ max: 62000000, rate: 0.0032647059 },
{ max: 65000000, rate: 0.0032058824 },
{ max: 68000000, rate: 0.0031470588 },
{ max: 71000000, rate: 0.0030882353 },
{ max: 74000000, rate: 0.0030294118 },
{ max: 77000000, rate: 0.0029705882 },
{ max: 80000000, rate: 0.0029117647 },
{ max: 83000000, rate: 0.0028529412 },
{ max: 86000000, rate: 0.0027941176 },
{ max: 89000000, rate: 0.0027352941 },
{ max: 92000000, rate: 0.0026764706 },
{ max: 95000000, rate: 0.0026176471 },
{ max: 98000000, rate: 0.0025588235 },
{ max: 100000000, rate: 0.0025 },
{ max: 130000000, rate: 0.0024766667 },
{ max: 160000000, rate: 0.0024533333 },
{ max: 190000000, rate: 0.00243 },
{ max: 220000000, rate: 0.0024066667 },
{ max: 250000000, rate: 0.0023833333 },
{ max: 280000000, rate: 0.00236 },
{ max: 310000000, rate: 0.0023366667 },
{ max: 340000000, rate: 0.0023133333 },
{ max: 370000000, rate: 0.00229 },
{ max: 400000000, rate: 0.0022666667 },
{ max: 430000000, rate: 0.0022433333 },
{ max: 460000000, rate: 0.00222 },
{ max: 490000000, rate: 0.0021966667 },
{ max: 520000000, rate: 0.0021733333 },
{ max: 550000000, rate: 0.00215 },
{ max: 580000000, rate: 0.0021266667 },
{ max: 610000000, rate: 0.0021033333 },
{ max: 640000000, rate: 0.00208 },
{ max: 670000000, rate: 0.0020566667 },
{ max: 700000000, rate: 0.0020333333 },
{ max: 730000000, rate: 0.00201 },
{ max: 760000000, rate: 0.0019866667 },
{ max: 790000000, rate: 0.0019633333 },
{ max: 820000000, rate: 0.00194 },
{ max: 850000000, rate: 0.0019166667 },
{ max: 880000000, rate: 0.0018933333 },
{ max: 910000000, rate: 0.00187 },
{ max: 940000000, rate: 0.0018466667 },
{ max: 970000000, rate: 0.0018233333 },
{ max: 1000000000, rate: 0.0018 },
{ max: 1120000000, rate: 0.001768 },
{ max: 1240000000, rate: 0.001736 },
{ max: 1360000000, rate: 0.001704 },
{ max: 1480000000, rate: 0.001672 },
{ max: 1600000000, rate: 0.00164 },
{ max: 1720000000, rate: 0.001608 },
{ max: 1840000000, rate: 0.001576 },
{ max: 1960000000, rate: 0.001544 },
{ max: 2080000000, rate: 0.001512 },
{ max: 2200000000, rate: 0.00148 },
{ max: 2320000000, rate: 0.001448 },
{ max: 2440000000, rate: 0.001416 },
{ max: 2560000000, rate: 0.001384 },
{ max: 2680000000, rate: 0.001352 },
{ max: 2800000000, rate: 0.00132 },
{ max: 2920000000, rate: 0.001288 },
{ max: 3040000000, rate: 0.001256 },
{ max: 3160000000, rate: 0.001224 },
{ max: 3280000000, rate: 0.001192 },
{ max: 3400000000, rate: 0.00116 },
{ max: 3520000000, rate: 0.001128 },
{ max: 3640000000, rate: 0.001096 },
{ max: 3760000000, rate: 0.001064 },
{ max: 3880000000, rate: 0.001032 },
{ max: 4000000000, rate: 0.001 },
{ max: Infinity, rate: 0.0009 }
];

/**
 * @typedef {Object} Transaction
 * @property {number} id
 * @property {string} name
 * @property {number} period
 * @property {Date} startDate
 * @property {Date} endDate
 * @property {number} days
 * @property {number} amount
 * @property {number} total
 * @property {number} rate
 * @property {number} fee
 * @property {string} periodType
 */

function calculateFees(startRevenue, endRevenue, tiers) {
  let totalFees = 0;
  let currentRevenue = startRevenue;
  let steps = [];

  for (let i = 0; i < tiers.length; i++) {
    const tier = tiers[i];
    
    if (currentRevenue >= tier.max) continue;
    
    const tierRevenue = Math.min(endRevenue, tier.max) - currentRevenue;
    if (tierRevenue <= 0) break;
    
    // Ensure the rate is treated as a decimal
    const rate = tier.rate;
    const stepFee = tierRevenue * rate;
    totalFees += stepFee;
    
    steps.push({
      tierMax: tier.max,
      rate: rate,
      revenue: tierRevenue,
      fees: stepFee
    });
    
    currentRevenue += tierRevenue;
    if (currentRevenue >= endRevenue) break;
  }

  const effectiveRate = totalFees / (endRevenue - startRevenue);

  return {
    totalFees: totalFees,
    effectiveRate: effectiveRate,
    steps: steps
  };
}

function formatCurrency(value) {
    return '$' + value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// If you plan to use this function in the future, keep it and add a comment to ignore the warning
// eslint-disable-next-line no-unused-vars
function formatRate(value) {
    return value.toFixed(8) + '%';
}

export default function FlexiblePeriodPricingCalculator({ isAdmin }) {
    // Move this function to the top of the component
    const formatRatePercentage = (rate) => {
        return (rate * 100).toFixed(4) + '%';
    };

    const formatterForBreakdown = (value, name) => {
        if (name === 'Rate') {
            return formatRatePercentage(value);
        }
        if (name === 'Cerebro Cost for Tier' || name === 'Revenue in Tier') {
            return formatCurrency(value);
        }
        return value;
    };

    const [newAmount, setNewAmount] = useState('');
    const [transactions, setTransactions] = useState([]);
    const [isCodeEditorVisible, setIsCodeEditorVisible] = useState(false);
    const [tiers, setTiers] = useState(initialTiers);
    const [activeChart, setActiveChart] = useState('period');
    const [timePeriod, setTimePeriod] = useState('monthly');
    const [timeAggregation, setTimeAggregation] = useState('month');
    const [collapsedYears, setCollapsedYears] = useState({});
    const [editingAmount, setEditingAmount] = useState(null);

    // Add a new state to track expanded periods
    const [expandedPeriods, setExpandedPeriods] = useState({});

    // Add this to your existing imports
    const [loadedScenarioName, setLoadedScenarioName] = useState(null);

    // Add new state for column visibility
    const [showRateAndCost, setShowRateAndCost] = useState(false);

    const [convertFunction, setConvertFunction] = useState(null);

    const [selectedCurrency, setSelectedCurrency] = useState('USD');

    const parsedTiers = useMemo(() => {
        try {
            return JSON.parse(JSON.stringify(tiers)).map(tier => ({
                ...tier,
                rate: parseFloat(tier.rate) // Ensure rate is a number
            }));
        } catch (error) {
            console.error('Invalid JSON for tiers:', error);
            return tiers;
        }
    }, [tiers]);

    const formatInputOnBlur = useCallback(() => {
        const numberValue = parseFloat(newAmount.replace(/,/g, ''));
        if (!isNaN(numberValue)) {
          const formatted = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).format(numberValue);
          setNewAmount(formatted);
        }
    }, [newAmount]);

    const calculateEndDate = useCallback((startDate, periodType) => {
      const endDate = new Date(startDate);
      switch (periodType) {
        case 'daily':
          endDate.setDate(endDate.getDate() + 1);
          break;
        case 'weekly':
          endDate.setDate(endDate.getDate() + 7);
          break;
        case 'monthly':
          const startDay = endDate.getDate();
          endDate.setMonth(endDate.getMonth() + 1);
          if (startDay === 1) {
            endDate.setDate(0); // Last day of the current month
          } else {
            endDate.setDate(startDay - 1); // Same day next month minus one day
          }
          break;
        case 'yearly':
          endDate.setFullYear(endDate.getFullYear() + 1);
          endDate.setDate(endDate.getDate() - 1); // Set to last day of the year
          break;
        default:
          console.warn(`Unknown period type: ${periodType}`);
          endDate.setDate(endDate.getDate() + 1);
      }
      return endDate;
    }, []);

    const calculateDaysBetween = useCallback((startDate, endDate) => {
      const diffTime = endDate.getTime() - startDate.getTime();
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)) + 1;
      return diffDays;
    }, []);

    const generatePeriodName = useCallback((startDate, endDate, periodType) => {
      if (!startDate || !endDate) return '';
      
      const start = new Date(startDate);
      
      if (periodType === 'yearly') {
        return start.getFullYear().toString();
      } else if (periodType === 'monthly') {
        return formatInTimeZone(start, 'UTC', 'MMM yyyy');
      } else if (periodType === 'weekly') {
        const end = new Date(endDate);
        // More concise weekly format
        return `W${formatInTimeZone(start, 'UTC', 'd')}-${formatInTimeZone(end, 'UTC', 'd')} ${formatInTimeZone(start, 'UTC', 'MMM')}`;
      } else {
        // Shorter daily format
        return formatInTimeZone(start, 'UTC', 'MMM d');
      }
    }, []);

    const addTransaction = useCallback(() => {
      if (!convertFunction) return;
      
      const conversion = convertFunction();
      if (!conversion || conversion.amount === null || conversion.amount <= 0) return;

      const { amount: usdAmount, rate: exchangeRate, originalAmount } = conversion;

      setTransactions(prevTransactions => {
        const prevTotal = prevTransactions.length > 0 ? prevTransactions[prevTransactions.length - 1].total : 0;
        const newTotal = prevTotal + usdAmount;
        const { totalFees, steps } = calculateFees(prevTotal, newTotal, parsedTiers);
        const effectiveRate = totalFees / usdAmount;

        // Create the dates first
        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);
        const endDate = calculateEndDate(startDate, timePeriod);
        
        if (timePeriod === 'yearly') {
          endDate.setFullYear(endDate.getFullYear() + 1);
          endDate.setDate(endDate.getDate() - 1);
        }
        
        const newTransaction = {
          id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
          name: generatePeriodName(startDate, endDate, timePeriod),
          period: prevTransactions.length + 1,
          startDate,
          endDate,
          days: calculateDaysBetween(startDate, endDate),
          amount: usdAmount,
          total: newTotal,
          rate: effectiveRate,
          fee: totalFees,
          periodType: timePeriod,
          steps: steps,
          originalCurrency: selectedCurrency,
          originalAmount: originalAmount,
          exchangeRate: exchangeRate,
        };

        return [...prevTransactions, newTransaction];
      });

      setNewAmount('');
    }, [
      parsedTiers,
      timePeriod,
      generatePeriodName,
      calculateEndDate,
      calculateDaysBetween,
      convertFunction,
      selectedCurrency
    ]);

    const handleStartDateChange = useCallback((id, newStartDateStr) => {
      setTransactions(prevTransactions => 
        prevTransactions.map(t => {
          if (t.id === id) {
            const newStartDate = parseISO(newStartDateStr);
            if (isNaN(newStartDate.getTime())) {
              return t;
            }
            const newEndDate = calculateEndDate(newStartDate, t.periodType);
            const newDays = calculateDaysBetween(newStartDate, newEndDate);
            const newName = generatePeriodName(newStartDate, newEndDate, t.periodType);
            return { ...t, startDate: newStartDate, endDate: newEndDate, days: newDays, name: newName };
          }
          return t;
        })
      );
    }, [generatePeriodName, calculateEndDate, calculateDaysBetween]);

    const handleAmountChange = useCallback((id, newAmount) => {
      setTransactions(prevTransactions => {
        const updatedTransactions = prevTransactions.map(t => {
          if (t.id === id) {
            // Remove non-numeric characters except for the decimal point
            let value = newAmount.replace(/[^0-9.]/g, '');
            
            // Ensure only one decimal point
            const parts = value.split('.');
            if (parts.length > 2) {
              parts[1] = parts.slice(1).join('');
              value = parts.join('.');
            }
            
            // Parse the value to a float
            const updatedAmount = parseFloat(value);
            
            return { ...t, amount: isNaN(updatedAmount) ? 0 : updatedAmount };
          }
          return t;
        });
        
        // Recalculate totals and fees
        let runningTotal = 0;
        return updatedTransactions.map(t => {
          const prevTotal = runningTotal;
          runningTotal += t.amount;
          const { totalFees, effectiveRate } = calculateFees(prevTotal, runningTotal, parsedTiers);
          return {
            ...t,
            total: runningTotal,
            rate: effectiveRate,
            fee: totalFees,
            periodFee: totalFees
          };
        });
      });
    }, [parsedTiers]);

    const deleteTransaction = (id) => {
        const updatedTransactions = [];
        let runningTotal = 0;
        
        transactions.forEach((t, index) => {
            if (t.id !== id) {
                const prevTotal = runningTotal;
                runningTotal += t.amount;
                const { totalFees, effectiveRate } = calculateFees(prevTotal, runningTotal, parsedTiers);
                updatedTransactions.push({
                    ...t,
                    period: updatedTransactions.length + 1,
                    total: runningTotal,
                    rate: effectiveRate,
                    fee: totalFees,
                    periodFee: totalFees
                });
            }
        });

        setTransactions(updatedTransactions);
    };

    const chartData = useMemo(() => {
        return transactions.map(t => ({
            period: `${t.name} (${t.periodType})`,
            total: t.total / 1000000,
            rate: t.rate,
            fee: t.fee / 1000000
        }));
    }, [transactions]);

    const cumulativeChartData = useMemo(() => {
        return transactions.map(t => ({
            cumulativeRevenue: t.total / 1000000,
            rate: t.rate,
            fee: t.fee
        }));
    }, [transactions]);

    const timeSeriesChartData = useMemo(() => {
      if (transactions.length === 0) return [];

      const minDate = new Date(Math.min(...transactions.map(t => new Date(t.startDate).getTime())));
      console.log('Minimum start date:', minDate);

      const aggregateData = (data, aggregationType) => {
        const aggregatedData = {};
        // Remove the weekly aggregation logic
        if (aggregationType === 'month' || aggregationType === 'year') {
          // For monthly and yearly aggregation, use the exact fee from each transaction
          transactions.forEach(t => {
            let key;
            if (aggregationType === 'month') {
              key = formatInTimeZone(new Date(t.startDate), 'UTC', 'yyyy-MM');
            } else { // year
              key = formatInTimeZone(new Date(t.startDate), 'UTC', 'yyyy');
            }
            if (!aggregatedData[key]) {
              aggregatedData[key] = { date: key, fee: 0 };
            }
            aggregatedData[key].fee += t.fee;
          });
        }
        return Object.values(aggregatedData).sort((a, b) => new Date(a.date) - new Date(b.date));
      };

      const result = aggregateData(transactions, timeAggregation);
      console.log('Time Series Chart Data:', result);
      return result;
    }, [transactions, timeAggregation]);

    const formatYAxis = (value) => {
        if (value >= 1000000) {
          return `$${(value / 1000000).toFixed(1)}M`;
        } else if (value >= 1000) {
          return `$${(value / 1000).toFixed(1)}K`;
        } else {
          return `$${value.toFixed(0)}`;
        }
    };

    const totalPeriods = transactions.length;
    const totalRevenue = transactions.reduce((sum, t) => sum + t.amount, 0);
    const totalFees = transactions.reduce((sum, t) => sum + t.fee, 0);
    // If you plan to use this variable in the future, keep it and add a comment to ignore the warning
    // eslint-disable-next-line no-unused-vars
    const effectiveOverallRate = totalRevenue > 0 ? (totalFees / totalRevenue) * 100 : 0;

    const handleTiersChange = useCallback((newTiers) => {
        setTiers(newTiers);
        // Recalculate all transactions with the new tiers
        setTransactions(prevTransactions => {
            let runningTotal = 0;
            return prevTransactions.map(t => {
                const prevTotal = runningTotal;
                runningTotal += t.amount;
                const { totalFees, effectiveRate } = calculateFees(prevTotal, runningTotal, newTiers);
                return {
                    ...t,
                    total: runningTotal,
                    rate: effectiveRate,
                    fee: totalFees,
                    periodFee: totalFees
                };
            });
        });
    }, []);

    const saveState = useCallback(() => {
      const standardizedTransactions = transactions.map(t => {
        // Ensure each transaction has a steps array
        const steps = t.steps || calculateFees(t.total - t.amount, t.total, parsedTiers).steps;

        return {
          id: t.id,
          name: t.name,
          period: t.period,
          startDate: t.startDate.toISOString(),
          endDate: t.endDate.toISOString(),
          days: t.days,
          amount: t.amount,
          total: t.total,
          rate: t.rate,
          fee: t.fee,
          periodType: t.periodType,
          steps: steps,
          // Add currency information
          originalCurrency: t.originalCurrency || 'USD',
          originalAmount: t.originalAmount || t.amount,
          exchangeRate: t.exchangeRate || 1,
        };
      });

      const state = {
        transactions: standardizedTransactions,
        tiers
      };

      const stateString = JSON.stringify(state, null, 2);
      const blob = new Blob([stateString], { type: 'application/json' });
      saveAs(blob, 'cerebro_pricing_state.json');
    }, [transactions, tiers, parsedTiers]);

    const loadState = useCallback((event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            const state = JSON.parse(e.target.result);
            setTiers(state.tiers);
            setTransactions(state.transactions.map(t => ({
              ...t,
              startDate: new Date(t.startDate),
              endDate: new Date(t.endDate),
              originalCurrency: t.originalCurrency || 'USD',
              originalAmount: parseFloat(t.originalAmount || t.amount),
            })));
            setLoadedScenarioName(file.name);
          } catch (error) {
            console.error('Error loading state:', error);
          }
        };
        reader.readAsText(file);
      }
    }, []);

    // eslint-disable-next-line no-unused-vars
    const averagePeriodFee = totalPeriods > 0 ? totalFees / totalPeriods : 0;

    const handleEndDateChange = useCallback((id, newEndDateStr) => {
      setTransactions(prevTransactions => 
        prevTransactions.map(t => {
          if (t.id === id) {
            const newEndDate = parseISO(newEndDateStr);
            if (isNaN(newEndDate.getTime())) {
              return t;
            }
            const newDays = calculateDaysBetween(t.startDate, newEndDate);
            const newName = generatePeriodName(t.startDate, newEndDate, t.periodType);
            return { ...t, endDate: newEndDate, days: newDays, name: newName };
          }
          return t;
        })
      );
    }, [generatePeriodName, calculateDaysBetween]);

    const formatXAxis = (value) => {
      return `$${(value).toFixed(1)}M`;
    };

    const formatNumberWithCommas = (number) => {
      // Handle null, undefined, or non-numeric values
      if (number === null || number === undefined || isNaN(number)) {
        return "0.00";
      }
      
      // Ensure the input is treated as a number
      const numValue = typeof number === 'string' ? parseFloat(number) : number;
      
      // Check if the conversion resulted in a valid number
      if (isNaN(numValue)) {
        return "0.00";
      }
      
      return numValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // Group transactions by year
    const transactionsByYear = useMemo(() => {
      const grouped = {};
      transactions.forEach(t => {
        const startDate = new Date(Date.UTC(
          t.startDate.getUTCFullYear(),
          t.startDate.getUTCMonth(),
          t.startDate.getUTCDate()
        ));
        const year = startDate.getUTCFullYear();
        if (!grouped[year]) {
          grouped[year] = [];
        }
        grouped[year].push(t);
      });
      return grouped;
    }, [transactions]);

    // If you plan to use this function in the future, keep it and add a comment to ignore the warning
    // eslint-disable-next-line no-unused-vars
    const calculateEffectiveRate = useCallback((amount, fee) => {
        return amount > 0 ? fee / amount : 0;
    }, []);

    // Update the yearSubtotals calculation
    const yearSubtotals = useMemo(() => {
        const subtotals = {};
        let cumulativeTotal = 0;
        Object.entries(transactionsByYear).forEach(([year, yearTransactions]) => {
            const yearTotal = yearTransactions.reduce((sum, t) => ({
                amount: sum.amount + t.amount,
                fee: sum.fee + t.fee,
                minDate: sum.minDate && sum.minDate < t.startDate ? sum.minDate : t.startDate,
                maxDate: sum.maxDate && sum.maxDate > t.endDate ? sum.maxDate : t.endDate
            }), { amount: 0, fee: 0, minDate: null, maxDate: null });
            
            cumulativeTotal += yearTotal.amount;
            
            subtotals[year] = {
                ...yearTotal,
                effectiveRate: yearTotal.amount > 0 ? yearTotal.fee / yearTotal.amount : 0,
                cumulativeTotal: cumulativeTotal // Add this line
            };
        });
        return subtotals;
    }, [transactionsByYear]);

    // Update the grand total calculation
    const grandTotal = useMemo(() => {
        const total = Object.values(yearSubtotals).reduce((sum, year) => ({
            amount: sum.amount + year.amount,
            fee: sum.fee + year.fee
        }), { amount: 0, fee: 0 });
        
        return {
            ...total,
            effectiveRate: total.amount > 0 ? total.fee / total.amount : 0
        };
    }, [yearSubtotals]);

    // Function to format date as MM/DD/YYYY in UTC
    const formatDateUTC = (date) => {
      return formatInTimeZone(new Date(date), 'UTC', 'MM/dd/yyyy');
    };

    // Function to toggle year collapse
    const toggleYearCollapse = useCallback((year) => {
      setCollapsedYears(prev => ({
        ...prev,
        [year]: !prev[year]
      }));
    }, []);

    // Update the formatter for the Tooltip
    const formatter = (value, name) => {
        if (name === 'Rate') {
            return [formatRatePercentage(parseFloat(value)), name];
        }
        return [`$${formatNumberWithCommas(parseFloat(value))}`, name];
    };

    // Function to toggle period expansion
    const togglePeriodExpansion = useCallback((periodId) => {
      setExpandedPeriods(prev => ({
        ...prev,
        [periodId]: !prev[periodId]
      }));
    }, []);

    const handleConvertedValueChange = useCallback((fn) => {
      setConvertFunction(() => fn);
    }, []);

    // Keep toTitleCase as it might be used elsewhere
    const toTitleCase = (str) => {
      return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    };

    // Update handleInputChange to work with transaction editing
    const handleInputChange = useCallback((e) => {
      let value = e.target.value.replace(/[^0-9.,]/g, '');
      value = value.replace(/,/g, '');
      const parts = value.split('.');
      if (parts.length > 2) {
        parts[1] = parts.slice(1).join('');
        value = parts.join('.');
      }
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      value = parts.join('.');
      
      if (editingAmount) {
        handleAmountChange(editingAmount, value);
      } else {
        setNewAmount(value);
      }
    }, [editingAmount, handleAmountChange]);

    // Update handleKeyPress to work with both new and existing transactions
    const handleKeyPress = useCallback((event) => {
      if (event.key === 'Enter') {
        if (editingAmount) {
          setEditingAmount(null); // Finish editing
        } else {
          formatInputOnBlur();
          addTransaction();
        }
      }
    }, [addTransaction, formatInputOnBlur, editingAmount]);

    return (
        <div 
          className="p-4 max-w-[1600px] mx-auto bg-white shadow-md rounded-lg"
        >
            <h2 className="text-2xl font-bold mb-2 text-center">Pricing Calculator</h2>
            {loadedScenarioName && (
                <p className="text-sm text-gray-600 mb-4 text-center">
                    Loaded scenario: {loadedScenarioName}
                </p>
            )}
            
            {/* Save/Load Scenario buttons */}
            <div className="mb-4 flex justify-end space-x-2">
              <label className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700 transition-colors cursor-pointer text-sm">
                Load Scenario
                <input
                  type="file"
                  accept=".json"
                  onChange={loadState}
                  className="hidden"
                />
              </label>
              <button
                onClick={saveState}
                className="bg-green-600 text-white px-3 py-1 rounded hover:bg-green-700 transition-colors text-sm"
              >
                Save Scenario
              </button>
            </div>
            
            {/* Time period selector and input field in one line */}
            <div className="mb-6 flex items-center space-x-4">
              <select
                id="timePeriod"
                value={timePeriod}
                onChange={(e) => setTimePeriod(e.target.value)}
                className="w-32 px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
              </select>
              <CurrencyConverter
                value={newAmount}
                onValueChange={setNewAmount}
                onConvertedValueChange={handleConvertedValueChange}
                onEnterPress={addTransaction}
                onCurrencyChange={setSelectedCurrency}
                className="flex-grow"
                placeholder={`Enter ${toTitleCase(timePeriod)} Revenue Forecast`}
              />
              <button 
                onClick={addTransaction}
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors shadow-sm"
              >
                Add Period
              </button>
            </div>

            {/* Modified Transactions table */}
            <div className="overflow-x-auto">
              <table className="w-full mb-4 border-collapse text-sm">
                <colgroup>
                  <col className="w-80"/>{/* Period */}
                  <col className="w-32"/>{/* Start Date */}
                  <col className="w-32"/>{/* End Date */}
                  <col className="w-[350px]"/>{/* Revenue Forecast */}
                  <col className="w-44"/>{/* Cumulative Total */}
                  {showRateAndCost && (<>
                    <col className="w-28"/>{/* Rate */}
                    <col className="w-40"/>{/* Cerebro Cost */}
                  </>)}
                  <col className="w-14"/>{/* Actions */}
                </colgroup>
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-3 py-2 text-left whitespace-nowrap">Period</th>
                    <th className="px-3 py-2 text-left whitespace-nowrap">Start Date</th>
                    <th className="px-3 py-2 text-left whitespace-nowrap">End Date</th>
                    <th className="px-3 py-2 text-right whitespace-nowrap">Revenue Forecast</th>
                    <th className="px-3 py-2 text-right whitespace-nowrap">Cumulative Total</th>
                    {showRateAndCost && (
                      <>
                        <th className="px-3 py-2 text-center whitespace-nowrap">Rate</th>
                        <th className="px-3 py-2 text-right whitespace-nowrap">Cerebro Cost</th>
                      </>
                    )}
                    <th className="px-3 py-2 text-center">
                      <button
                        onClick={() => setShowRateAndCost(!showRateAndCost)}
                        className="text-gray-600 hover:text-gray-800 focus:outline-none flex items-center justify-center w-full h-full"
                        aria-label={showRateAndCost ? "Hide Rate and Cost" : "Show Rate and Cost"}
                      >
                        {showRateAndCost ? <FaMinusSquare size={16} /> : <FaPlusSquare size={16} />}
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(transactionsByYear).map(([year, yearTransactions]) => (
                    <React.Fragment key={year}>
                      <tr className="bg-gray-200 cursor-pointer hover:bg-gray-300" onClick={() => toggleYearCollapse(year)}>
                        <td className="px-3 py-2 font-bold">
                          <span className="mr-2">{collapsedYears[year] ? '▶' : '▼'}</span>
                          {year}
                        </td>
                        <td className="px-3 py-2 text-left">{formatDateUTC(yearSubtotals[year].minDate)}</td>
                        <td className="px-3 py-2 text-left">{formatDateUTC(yearSubtotals[year].maxDate)}</td>
                        <td className="px-3 py-2 text-right font-semibold">${formatNumberWithCommas(yearSubtotals[year].amount)}</td>
                        <td className="px-3 py-2 text-right font-semibold">${formatNumberWithCommas(yearSubtotals[year].cumulativeTotal)}</td>
                        {showRateAndCost && (
                          <>
                            <td className="px-3 py-2 text-center font-semibold">{formatRatePercentage(yearSubtotals[year].effectiveRate)}</td>
                            <td className="px-3 py-2 text-right font-semibold">${formatNumberWithCommas(yearSubtotals[year].fee)}</td>
                          </>
                        )}
                        <td className="px-3 py-2 text-center"></td>
                      </tr>
                      {!collapsedYears[year] && yearTransactions.map((t) => (
                        <React.Fragment key={t.id}>
                          <tr className="bg-white hover:bg-gray-50">
                            <td className="px-3 py-2 whitespace-nowrap">
                              <button
                                onClick={() => togglePeriodExpansion(t.id)}
                                className="text-left hover:text-blue-600 focus:outline-none w-full truncate"
                              >
                                {t.name}
                              </button>
                            </td>
                            <td className="px-3 py-2">
                              <input
                                type="date"
                                value={formatInTimeZone(t.startDate, 'UTC', 'yyyy-MM-dd')}
                                onChange={(e) => handleStartDateChange(t.id, e.target.value)}
                                className="border rounded px-1 py-0.5 w-full text-sm"
                              />
                            </td>
                            <td className="px-3 py-2">
                              <input
                                type="date"
                                value={formatInTimeZone(t.endDate, 'UTC', 'yyyy-MM-dd')}
                                onChange={(e) => handleEndDateChange(t.id, e.target.value)}
                                className="border rounded px-1 py-0.5 w-full text-sm"
                              />
                            </td>
                            <td className="px-3 py-2 text-right relative">
                              <div className="flex justify-end items-center gap-3"> {/* Changed space-x-2 to gap-3 */}
                                <div className="text-xs text-gray-500 whitespace-nowrap min-w-[120px] text-right">
                                  {t.originalCurrency} {formatNumberWithCommas(t.originalAmount)}
                                </div>
                                <div className="flex items-center">
                                  {t.originalCurrency !== 'USD' && (
                                    <span className="text-xs text-gray-500 mx-1">→</span>
                                  )}
                                  <input
                                    type="text"
                                    value={editingAmount === t.id ? t.amount : formatNumberWithCommas(t.amount)}
                                    onChange={(e) => handleInputChange(e)}
                                    onKeyPress={handleKeyPress}
                                    onFocus={() => setEditingAmount(t.id)}
                                    onBlur={() => setEditingAmount(null)}
                                    className="border rounded px-2 py-1 w-28 text-right text-sm"
                                  />
                                </div>
                              </div>
                            </td>
                            <td className="px-3 py-2 text-right">${formatNumberWithCommas(t.total)}</td>
                            {showRateAndCost && (
                              <>
                                <td className="px-3 py-2 text-center">{formatRatePercentage(t.rate)}</td>
                                <td className="px-3 py-2 text-right">{formatCurrency(t.fee)}</td>
                              </>
                            )}
                            <td className="px-3 py-2 text-center">
                              <button 
                                onClick={() => deleteTransaction(t.id)} 
                                className="text-red-600 hover:text-red-700 focus:outline-none"
                                aria-label="Delete transaction"
                              >
                                <FaTrash size={14} />
                              </button>
                            </td>
                          </tr>
                          {expandedPeriods[t.id] && (
                            <tr key={`${t.id}-breakdown`}>
                              <td colSpan="8" className="p-0">
                                <div className="text-sm pl-6 pr-2 py-2 bg-gray-50">
                                  <h4 className="font-semibold mb-2">Detailed Breakdown for {t.name}</h4>
                                  <table className="w-full">
                                    <colgroup><col/><col/><col/><col/></colgroup>
                                    <thead>
                                      <tr className="bg-gray-100">
                                        <th className="px-2 py-1 text-left">Tier Max</th>
                                        <th className="px-2 py-1 text-left">Rate</th>
                                        <th className="px-2 py-1 text-right">Revenue in Tier</th>
                                        <th className="px-2 py-1 text-right">Cerebro Cost for Tier</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {calculateFees(t.total - t.amount, t.total, parsedTiers).steps.map((step, stepIndex) => (
                                        <tr key={`${t.id}-step-${stepIndex}`} className={stepIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                          <td className="px-2 py-1">{formatCurrency(step.tierMax)}</td>
                                          <td className="px-2 py-1">{formatterForBreakdown(step.rate, 'Rate')}</td>
                                          <td className="px-2 py-1 text-right">{formatterForBreakdown(step.revenue, 'Revenue in Tier')}</td>
                                          <td className="px-2 py-1 text-right">{formatterForBreakdown(step.fees, 'Cerebro Cost for Tier')}</td>
                                        </tr>
                                      ))}
                                      <tr className="font-semibold bg-gray-100">
                                        <td className="px-2 py-1">Total</td>
                                        <td className="px-2 py-1">{formatRatePercentage(t.rate)}</td>
                                        <td className="px-2 py-1 text-right">{formatCurrency(t.amount)}</td>
                                        <td className="px-2 py-1 text-right">{formatCurrency(t.fee)}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="font-bold bg-gray-300">
                    <td className="px-3 py-2" colSpan="3">Grand Total:</td>
                    <td className="px-3 py-2 text-right">${formatNumberWithCommas(grandTotal.amount)}</td>
                    <td className="px-3 py-2 text-right">${formatNumberWithCommas(grandTotal.amount)}</td>
                    {showRateAndCost && (
                      <>
                        <td className="px-3 py-2 text-center">{formatRatePercentage(grandTotal.effectiveRate)}</td>
                        <td className="px-3 py-2 text-right">${formatNumberWithCommas(grandTotal.fee)}</td>
                      </>
                    )}
                    <td></td>
                  </tr>
                </tfoot>
              </table>
            </div>

            {/* Charts */}
            {transactions.length > 0 && (
                <div className="mb-4">
                    {/* TODO: Update Recharts implementation when they release support for React 18's removal of defaultProps
                        Current warnings about XAxis/YAxis defaultProps can be addressed when Recharts updates their library
                        Track issue: https://github.com/recharts/recharts/issues/3615
                    */}
                    <div className="flex justify-between items-center mb-2">
                        <h3 className="text-lg font-bold">Charts</h3>
                        <div>
                            <button
                                onClick={() => setActiveChart('period')}
                                className={`mr-2 px-3 py-1 rounded ${activeChart === 'period' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                            >
                                Rate by Period
                            </button>
                            <button
                                onClick={() => setActiveChart('cumulativeRate')}
                                className={`mr-2 px-3 py-1 rounded ${activeChart === 'cumulativeRate' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                            >
                                Rate by Revenue
                            </button>
                            <button
                                onClick={() => setActiveChart('cumulativeFee')}
                                className={`mr-2 px-3 py-1 rounded ${activeChart === 'cumulativeFee' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                            >
                                Cerebro Cost by Revenue
                            </button>
                            <button
                                onClick={() => setActiveChart('timeSeries')}
                                className={`px-3 py-1 rounded ${activeChart === 'timeSeries' ? 'bg-blue-600 text-white' : 'bg-gray-200'}`}
                            >
                                Cerebro Cost Over Time
                            </button>
                        </div>
                    </div>
                    {activeChart === 'timeSeries' && (
                        <div className="mb-2">
                            <label className="mr-2">Aggregate by:</label>
                            <select
                                value={timeAggregation}
                                onChange={(e) => setTimeAggregation(e.target.value)}
                                className="border rounded px-2 py-1"
                            >
                                <option value="month">Month</option>
                                <option value="year">Year</option>
                            </select>
                        </div>
                    )}
                    <ResponsiveContainer width="100%" height={400}>
                        {activeChart === 'timeSeries' && (
                            <LineChart data={timeSeriesChartData} margin={{ top: 20, right: 30, left: 60, bottom: 20 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    label={{ value: 'Time', position: 'insideBottom', offset: -10 }}
                                    tickFormatter={(tick) => formatInTimeZone(parseISO(tick), 'UTC', 'MMM yyyy')}
                                    scale="auto"
                                    allowDuplicatedCategory={true}
                                />
                                <YAxis
                                    label={{ value: 'Cerebro Cost', angle: -90, position: 'insideLeft', offset: -40 }}
                                    tickFormatter={formatYAxis}
                                    scale="auto"
                                    allowDuplicatedCategory={true}
                                />
                                <Tooltip 
                                  formatter={(value, name) => {
                                    if (name === 'Cerebro Cost') {
                                      return [`$${formatNumberWithCommas(parseFloat(value))}`, name];
                                    }
                                    return [value, name];
                                  }}
                                />
                                <Legend wrapperStyle={{ paddingTop: '20px' }}/>
                                <Line type="monotone" dataKey="fee" name="Cerebro Cost" stroke="#8884d8" />
                            </LineChart>
                        )}
                        {activeChart === 'period' && (
                            <LineChart data={chartData} margin={{ top: 20, right: 30, left: 60, bottom: 20 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis 
                                    dataKey="period" 
                                    label={{ value: 'Period', position: 'insideBottom', offset: -10 }}
                                />
                                <YAxis 
                                    label={{ value: 'Rate (%)', angle: -90, position: 'insideLeft', offset: -40 }}
                                    tickFormatter={(value) => `${(value * 100).toFixed(2)}%`}
                                />
                                <Tooltip formatter={formatter} />
                                <Legend wrapperStyle={{ paddingTop: '20px' }}/>
                                <Line type="monotone" dataKey="rate" name="Rate" stroke="#8884d8" />
                            </LineChart>
                        )}
                        {activeChart === 'cumulativeRate' && (
                            <LineChart data={cumulativeChartData} margin={{ top: 20, right: 30, left: 60, bottom: 20 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis 
                                    dataKey="cumulativeRevenue" 
                                    label={{ value: 'Cumulative Revenue (Millions)', position: 'insideBottom', offset: -10 }}
                                    tickFormatter={formatXAxis}
                                />
                                <YAxis 
                                    label={{ value: 'Rate (%)', angle: -90, position: 'insideLeft', offset: -40 }}
                                    tickFormatter={(value) => `${(value * 100).toFixed(2)}%`}
                                />
                                <Tooltip formatter={formatter} />
                                <Legend wrapperStyle={{ paddingTop: '20px' }}/>
                                <Line type="monotone" dataKey="rate" name="Rate" stroke="#82ca9d" />
                            </LineChart>
                        )}
                        {activeChart === 'cumulativeFee' && (
                            <LineChart data={cumulativeChartData} margin={{ top: 20, right: 30, left: 60, bottom: 20 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis 
                                    dataKey="cumulativeRevenue" 
                                    label={{ value: 'Cumulative Revenue (Millions)', position: 'insideBottom', offset: -10 }}
                                    tickFormatter={formatXAxis}
                                />
                                <YAxis 
                                    label={{ value: 'Cerebro Cost', angle: -90, position: 'insideLeft', offset: -40 }}
                                    tickFormatter={formatYAxis}
                                />
                                <Tooltip 
                                  formatter={(value, name) => {
                                    if (name === 'Cerebro Cost') {
                                      return [`$${formatNumberWithCommas(parseFloat(value))}`, name];
                                    }
                                    return [value, name];
                                  }}
                                />
                                <Legend wrapperStyle={{ paddingTop: '20px' }}/>
                                <Line type="monotone" dataKey="fee" name="Cerebro Cost" stroke="#ffc658" />
                            </LineChart>
                        )}
                    </ResponsiveContainer>
                </div>
            )}

            {/* Tiers Editor */}
            {isAdmin && (
              <div className="mt-4">
                <button
                  onClick={() => setIsCodeEditorVisible(!isCodeEditorVisible)}
                  className="mb-4 bg-gray-300 p-2 rounded hover:bg-gray-400 transition-colors"
                >
                  {isCodeEditorVisible ? 'Hide' : 'Show'} Tiers Editor
                </button>
                
                {isCodeEditorVisible && (
                  <div className="mb-4">
                    <p className="text-sm text-gray-600 mb-2">
                      <strong>Note:</strong> For best results, use Command+A (or Ctrl+A) to select all, copy this into a spreadsheet, 
                      edit there, and then copy back here with your desired changes. Click "Save Changes" to apply your modifications.
                    </p>
                    <TiersEditor tiers={tiers} onChange={handleTiersChange} />
                  </div>
                )}  
              </div>
            )}
        </div>
    );
}
// Currency configuration
export const CURRENCIES = {
  USD: { symbol: '$', name: 'US Dollar', rate: 1 },
  EUR: { symbol: '€', name: 'Euro', rate: 0.967101 },
  GBP: { symbol: '£', name: 'British Pound', rate: 0.001447 },
  CAD: { symbol: 'C$', name: 'Canadian Dollar', rate: 1.436892 },
  AUD: { symbol: 'A$', name: 'Australian Dollar', rate: 1.60505 },
  JPY: { symbol: '¥', name: 'Japanese Yen', rate: 158.03142994 }
};

// Environment variables with explicit fallbacks
const APP_ID = process.env.REACT_APP_EXCHANGE_RATES_API_ID;
const BASE_URL = process.env.REACT_APP_EXCHANGE_RATES_API_URL;

// Debug environment variables
console.log('Environment Variables:', {
  FOUND_APP_ID: !!process.env.REACT_APP_EXCHANGE_RATES_API_ID,
  FOUND_BASE_URL: !!process.env.REACT_APP_EXCHANGE_RATES_API_URL,
  USING_APP_ID: !!APP_ID,
  USING_BASE_URL: !!BASE_URL,
  NODE_ENV: process.env.NODE_ENV
});

// Fallback rates derived from CURRENCIES configuration
const FALLBACK_RATES = Object.fromEntries(
  Object.entries(CURRENCIES).map(([code, data]) => [code, data.rate])
);

async function fetchExchangeRates() {
  try {
    // Try to fetch from API if environment variables exist
    if (process.env.REACT_APP_EXCHANGE_RATES_API_URL && process.env.REACT_APP_EXCHANGE_RATES_API_ID) {
      const response = await fetch(`${process.env.REACT_APP_EXCHANGE_RATES_API_URL}/latest.json?app_id=${process.env.REACT_APP_EXCHANGE_RATES_API_ID}`, {
        headers: {
          'Accept': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      
      const data = await response.json();
      return {
        rates: data.rates,
        timestamp: data.timestamp,
        source: 'api'
      };
    }
    
    // Return fallback rates if no environment variables
    return {
      rates: FALLBACK_RATES,
      timestamp: Math.floor(Date.now() / 1000),
      source: 'fallback'
    };
  } catch (error) {
    console.warn('Failed to fetch exchange rates, using fallback rates', error);
    return {
      rates: FALLBACK_RATES,
      timestamp: Math.floor(Date.now() / 1000),
      source: 'fallback'
    };
  }
}

export { fetchExchangeRates }; 
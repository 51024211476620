import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchExchangeRates } from '../services/exchangeRates';
import { CURRENCIES } from '../services/exchangeRates';

function CurrencyConverter({ value, onValueChange, onConvertedValueChange, onEnterPress, onCurrencyChange, className, placeholder }) {
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [localValue, setLocalValue] = useState(value);
  const [rates, setRates] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  // Fetch exchange rates on component mount and every hour
  useEffect(() => {
    const fetchRates = async () => {
      const data = await fetchExchangeRates();
      setRates(data.rates);
      setLastUpdated(new Date(data.timestamp * 1000));
      
      // Log rate source
      if (data.source === 'fallback') {
        console.warn('⚠️ Using fallback exchange rates');
      }
    };

    fetchRates();
    const interval = setInterval(fetchRates, 60 * 60 * 1000); // Refresh every hour

    return () => clearInterval(interval);
  }, []);

  const convertToUSD = useCallback(() => {
    if (!localValue || !rates) return null;
    
    const numericValue = parseFloat(localValue.replace(/[^0-9.]/g, ''));
    if (isNaN(numericValue)) return null;
    
    if (selectedCurrency === 'USD') {
      return { 
        amount: numericValue, 
        rate: 1,
        originalAmount: numericValue 
      };
    }
    
    const rate = rates[selectedCurrency];
    const usdValue = (numericValue / rate);
    return {
      amount: parseFloat(usdValue.toFixed(2)),
      rate: rate,
      originalAmount: numericValue
    };
  }, [localValue, selectedCurrency, rates]);

  // Ensure the conversion function is always up to date in parent
  useEffect(() => {
    onConvertedValueChange(convertToUSD);
  }, [convertToUSD, onConvertedValueChange]);

  // Add this effect to expose the selected currency to parent
  useEffect(() => {
    if (onCurrencyChange) {
      onCurrencyChange(selectedCurrency);
    }
  }, [selectedCurrency, onCurrencyChange]);

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };

  const formatValue = (value) => {
    if (!value) return '';
    const numericValue = value.replace(/[^0-9.]/g, '');
    const parts = numericValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  };

  const handleValueChange = (e) => {
    const rawValue = e.target.value.replace(/[^0-9.,]/g, '');
    const formattedValue = formatValue(rawValue);
    setLocalValue(formattedValue);
    onValueChange(formattedValue);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const converted = convertToUSD();
      if (converted !== null) {
        onValueChange(formatValue(converted.amount.toString()));
        if (onEnterPress) {
          onEnterPress();
        }
      }
    }
  };

  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      <select
        value={selectedCurrency}
        onChange={handleCurrencyChange}
        className="border border-gray-300 rounded-md shadow-sm px-3 py-2 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        {Object.entries(CURRENCIES).map(([code, { name }]) => (
          <option key={code} value={code}>
            {code} - {name}
          </option>
        ))}
      </select>
      <input
        type="text"
        value={localValue}
        onChange={handleValueChange}
        onKeyPress={handleKeyPress}
        placeholder={placeholder || `Enter amount in ${selectedCurrency}`}
        className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
      {selectedCurrency !== 'USD' && localValue && (
        <div className="text-sm text-gray-500">
          ≈ ${formatValue(convertToUSD()?.amount?.toString() || '0')} USD
          {lastUpdated && (
            <div className="text-xs text-gray-400">
              Rate updated: {lastUpdated.toLocaleTimeString()}
              {rates && Object.keys(rates).length > 0 ? '' : ' (using fallback rates)'}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

CurrencyConverter.propTypes = {
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onConvertedValueChange: PropTypes.func.isRequired,
  onEnterPress: PropTypes.func,
  onCurrencyChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

export default CurrencyConverter; 